import React from 'react'
import { Group, HStack, Headline, Space, VStack } from '@themeable/react-components'


function Logo({ width }) {
    return (
        <Group>
            <a href='../'>
                <svg viewBox="0 0 112 24" fill="none" xmlns="http://www.w3.org/2000/svg" width={ width }>
                    <path d="M15 3H16C17.8692 3 18.8038 3 19.5 3.40192C19.9561 3.66523 20.3348 4.04394 20.5981 4.5C21 5.19615 21 6.13077 21 8V8H15V3Z" fill="url(#paint0_linear_1565_30167)"/>
                    <path d="M3 3H5C6.86923 3 7.80385 3 8.5 3.40192C8.95606 3.66523 9.33477 4.04394 9.59808 4.5C10 5.19615 10 6.13077 10 8V8H3V3Z" fill="url(#paint1_linear_1565_30167)"/>
                    <path d="M10 8H15V17C15 18.8692 15 19.8038 14.5981 20.5C14.3348 20.9561 13.9561 21.3348 13.5 21.5981C12.8038 22 11.8692 22 10 22V22V8Z" fill="url(#paint2_linear_1565_30167)"/>
                    <path d="M107.925 18.1745C107.084 18.1745 106.339 17.9983 105.691 17.646C105.054 17.2822 104.554 16.7763 104.191 16.1284C103.838 15.4691 103.662 14.6961 103.662 13.8094V13.6048C103.662 12.7182 103.838 11.9509 104.191 11.3029C104.543 10.6436 105.037 10.1378 105.674 9.78538C106.311 9.42163 107.049 9.23975 107.891 9.23975C108.72 9.23975 109.442 9.42731 110.056 9.80243C110.67 10.1662 111.147 10.6777 111.488 11.337C111.829 11.985 112 12.7409 112 13.6048V14.338H105.845C105.867 14.9178 106.083 15.3895 106.492 15.7533C106.902 16.117 107.402 16.2989 107.993 16.2989C108.595 16.2989 109.039 16.1682 109.323 15.9067C109.607 15.6453 109.823 15.3554 109.971 15.0371L111.727 15.9579C111.568 16.2534 111.335 16.5774 111.028 16.9298C110.733 17.2708 110.335 17.5664 109.834 17.8165C109.334 18.0552 108.698 18.1745 107.925 18.1745ZM105.862 12.7352H109.817C109.772 12.2464 109.573 11.8543 109.221 11.5587C108.88 11.2631 108.431 11.1154 107.874 11.1154C107.294 11.1154 106.834 11.2631 106.492 11.5587C106.151 11.8543 105.941 12.2464 105.862 12.7352Z" fill="rgba(var(--color-text-primary), var(--color-text-primary-alpha))"/>
                    <path d="M100.635 17.9358V6H102.784V17.9358H100.635Z" fill="rgba(var(--color-text-primary), var(--color-text-primary-alpha))"/>
                    <path d="M95.9363 18.1745C95.1747 18.1745 94.5893 18.0438 94.1801 17.7823C93.7708 17.5209 93.4696 17.231 93.2764 16.9127H92.9694V17.9358H90.8551V6H93.0035V10.4503H93.3105C93.4355 10.2457 93.6003 10.0525 93.8049 9.87061C94.0209 9.68873 94.2994 9.54095 94.6405 9.42728C94.9928 9.30224 95.4248 9.23971 95.9363 9.23971C96.6184 9.23971 97.2493 9.41023 97.829 9.75125C98.4088 10.0809 98.8748 10.5697 99.2272 11.2176C99.5796 11.8656 99.7558 12.6499 99.7558 13.5707V13.8435C99.7558 14.7643 99.5796 15.5486 99.2272 16.1966C98.8748 16.8445 98.4088 17.339 97.829 17.68C97.2493 18.0097 96.6184 18.1745 95.9363 18.1745ZM95.2884 16.2989C95.9477 16.2989 96.499 16.0886 96.9424 15.668C97.3857 15.236 97.6073 14.6108 97.6073 13.7924V13.6219C97.6073 12.8034 97.3857 12.1839 96.9424 11.7633C96.5104 11.3313 95.9591 11.1153 95.2884 11.1153C94.6291 11.1153 94.0778 11.3313 93.6344 11.7633C93.1911 12.1839 92.9694 12.8034 92.9694 13.6219V13.7924C92.9694 14.6108 93.1911 15.236 93.6344 15.668C94.0778 16.0886 94.6291 16.2989 95.2884 16.2989Z" fill="rgba(var(--color-text-primary), var(--color-text-primary-alpha))"/>
                    <path d="M84.4658 18.1745C83.8633 18.1745 83.3234 18.0722 82.846 17.8676C82.3685 17.6516 81.9877 17.3447 81.7035 16.9469C81.4307 16.5376 81.2943 16.0431 81.2943 15.4634C81.2943 14.8837 81.4307 14.4006 81.7035 14.0141C81.9877 13.6162 82.3742 13.3207 82.863 13.1274C83.3632 12.9228 83.9316 12.8205 84.5681 12.8205H86.8871V12.3431C86.8871 11.9452 86.762 11.6212 86.512 11.3711C86.2619 11.1097 85.864 10.979 85.3184 10.979C84.7841 10.979 84.3862 11.104 84.1248 11.3541C83.8633 11.5928 83.6928 11.9054 83.6133 12.2919L81.6353 11.6269C81.7717 11.1949 81.9877 10.8028 82.2833 10.4504C82.5902 10.0866 82.9937 9.79675 83.4939 9.58077C84.0054 9.35342 84.625 9.23975 85.3525 9.23975C86.4665 9.23975 87.3475 9.51825 87.9954 10.0753C88.6433 10.6323 88.9673 11.4393 88.9673 12.4965V15.651C88.9673 15.992 89.1265 16.1625 89.4448 16.1625H90.1268V17.9358H88.6945C88.2739 17.9358 87.9272 17.8335 87.6544 17.6289C87.3816 17.4243 87.2452 17.1515 87.2452 16.8104V16.7934H86.9212C86.8757 16.9298 86.7734 17.1117 86.6143 17.339C86.4551 17.555 86.205 17.7483 85.864 17.9188C85.523 18.0893 85.0569 18.1745 84.4658 18.1745ZM84.8409 16.4353C85.4434 16.4353 85.9322 16.2705 86.3073 15.9408C86.6938 15.5998 86.8871 15.1508 86.8871 14.5938V14.4233H84.7216C84.3237 14.4233 84.0111 14.5085 83.7838 14.6791C83.5564 14.8496 83.4428 15.0883 83.4428 15.3952C83.4428 15.7021 83.5621 15.9522 83.8008 16.1455C84.0395 16.3387 84.3862 16.4353 84.8409 16.4353Z" fill="rgba(var(--color-text-primary), var(--color-text-primary-alpha))"/>
                    <path d="M76.4442 18.1745C75.6031 18.1745 74.8585 17.9983 74.2105 17.646C73.574 17.2822 73.0738 16.7763 72.71 16.1284C72.3577 15.4691 72.1815 14.6961 72.1815 13.8094V13.6048C72.1815 12.7182 72.3577 11.9509 72.71 11.3029C73.0624 10.6436 73.5569 10.1378 74.1935 9.78538C74.8301 9.42163 75.5689 9.23975 76.4101 9.23975C77.24 9.23975 77.9618 9.42731 78.5756 9.80243C79.1895 10.1662 79.6669 10.6777 80.0079 11.337C80.3489 11.985 80.5195 12.7409 80.5195 13.6048V14.338H74.364C74.3867 14.9178 74.6027 15.3895 75.0119 15.7533C75.4212 16.117 75.9213 16.2989 76.5124 16.2989C77.1149 16.2989 77.5582 16.1682 77.8424 15.9067C78.1266 15.6453 78.3426 15.3554 78.4904 15.0371L80.2466 15.9579C80.0875 16.2534 79.8545 16.5774 79.5475 16.9298C79.252 17.2708 78.8541 17.5664 78.354 17.8165C77.8538 18.0552 77.2172 18.1745 76.4442 18.1745ZM74.3811 12.7352H78.3369C78.2914 12.2464 78.0925 11.8543 77.7401 11.5587C77.3991 11.2631 76.9501 11.1154 76.3931 11.1154C75.8133 11.1154 75.353 11.2631 75.0119 11.5587C74.6709 11.8543 74.4606 12.2464 74.3811 12.7352Z" fill="rgba(var(--color-text-primary), var(--color-text-primary-alpha))"/>
                    <path d="M58.8798 17.9358V9.47845H60.9942V10.3992H61.3011C61.4489 10.115 61.6933 9.87063 62.0343 9.66601C62.3753 9.45003 62.8243 9.34204 63.3813 9.34204C63.9838 9.34204 64.4669 9.4614 64.8307 9.70011C65.1944 9.92746 65.4729 10.2287 65.6662 10.6038H65.9731C66.1663 10.2401 66.4392 9.93883 66.7915 9.70011C67.1439 9.4614 67.6441 9.34204 68.292 9.34204C68.8149 9.34204 69.2867 9.45571 69.7073 9.68306C70.1393 9.89904 70.4803 10.2344 70.7304 10.6891C70.9918 11.1324 71.1225 11.6951 71.1225 12.3771V17.9358H68.9741V12.5306C68.9741 12.0645 68.8547 11.7178 68.616 11.4905C68.3773 11.2518 68.042 11.1324 67.61 11.1324C67.1212 11.1324 66.7404 11.2916 66.4676 11.6098C66.2061 11.9168 66.0754 12.3601 66.0754 12.9398V17.9358H63.927V12.5306C63.927 12.0645 63.8076 11.7178 63.5689 11.4905C63.3302 11.2518 62.9948 11.1324 62.5629 11.1324C62.0741 11.1324 61.6933 11.2916 61.4204 11.6098C61.159 11.9168 61.0283 12.3601 61.0283 12.9398V17.9358H58.8798Z" fill="rgba(var(--color-text-primary), var(--color-text-primary-alpha))"/>
                    <path d="M53.7348 18.1745C52.8936 18.1745 52.1491 17.9983 51.5011 17.646C50.8646 17.2822 50.3644 16.7763 50.0006 16.1284C49.6482 15.4691 49.472 14.6961 49.472 13.8094V13.6048C49.472 12.7182 49.6482 11.9509 50.0006 11.3029C50.353 10.6436 50.8475 10.1378 51.4841 9.78538C52.1207 9.42163 52.8595 9.23975 53.7007 9.23975C54.5305 9.23975 55.2524 9.42731 55.8662 9.80243C56.4801 10.1662 56.9575 10.6777 57.2985 11.337C57.6395 11.985 57.8101 12.7409 57.8101 13.6048V14.338H51.6546C51.6773 14.9178 51.8933 15.3895 52.3025 15.7533C52.7118 16.117 53.2119 16.2989 53.803 16.2989C54.4055 16.2989 54.8488 16.1682 55.133 15.9067C55.4172 15.6453 55.6332 15.3554 55.781 15.0371L57.5372 15.9579C57.3781 16.2534 57.1451 16.5774 56.8381 16.9298C56.5426 17.2708 56.1447 17.5664 55.6446 17.8165C55.1444 18.0552 54.5078 18.1745 53.7348 18.1745ZM51.6716 12.7352H55.6275C55.582 12.2464 55.3831 11.8543 55.0307 11.5587C54.6897 11.2631 54.2407 11.1154 53.6837 11.1154C53.1039 11.1154 52.6436 11.2631 52.3025 11.5587C51.9615 11.8543 51.7512 12.2464 51.6716 12.7352Z" fill="rgba(var(--color-text-primary), var(--color-text-primary-alpha))"/>
                    <path d="M40.2168 17.9358V6H42.3652V10.5185H42.6722C42.7631 10.3367 42.9052 10.1548 43.0984 9.97291C43.2917 9.79103 43.5475 9.64326 43.8657 9.52958C44.1954 9.40454 44.6103 9.34202 45.1105 9.34202C45.7698 9.34202 46.3438 9.49548 46.8326 9.8024C47.3328 10.098 47.7193 10.5129 47.9921 11.0471C48.2649 11.57 48.4013 12.1839 48.4013 12.8887V17.9358H46.2529V13.0592C46.2529 12.4226 46.0938 11.9452 45.7755 11.6269C45.4685 11.3086 45.0252 11.1494 44.4455 11.1494C43.7862 11.1494 43.2746 11.3711 42.9109 11.8144C42.5471 12.2464 42.3652 12.8546 42.3652 13.6389V17.9358H40.2168Z" fill="rgba(var(--color-text-primary), var(--color-text-primary-alpha))" />
                    <path d="M33.4784 17.9358V8.04614H30V6H39.2076V8.04614H35.7292V17.9358H33.4784Z" fill="rgba(var(--color-text-primary), var(--color-text-primary-alpha))"/>
                    <defs>
                    <linearGradient id="paint0_linear_1565_30167" x1="20.5" y1="4" x2="15" y2="8" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#9155FE"/>
                    <stop offset="1" stop-color="#1EB0FE"/>
                    </linearGradient>
                    <linearGradient id="paint1_linear_1565_30167" x1="3" y1="3" x2="10" y2="8" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#F2532E"/>
                    <stop offset="0.392526" stop-color="#AD8BC2"/>
                    <stop offset="0.520516" stop-color="#8594DD"/>
                    <stop offset="1" stop-color="#1BB6FF"/>
                    </linearGradient>
                    <linearGradient id="paint2_linear_1565_30167" x1="10" y1="8" x2="14" y2="21.5" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#1AB5FF"/>
                    <stop offset="1" stop-color="#0EC979"/>
                    </linearGradient>
                    </defs>
                </svg>
            </a>
        </Group>
    )
}

function LogoShort({ width }) {
  return (
    <Group>
        <a href='../'>
            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" width={ width }>
                <path d="M15 3H16C17.8692 3 18.8038 3 19.5 3.40192C19.9561 3.66523 20.3348 4.04394 20.5981 4.5C21 5.19615 21 6.13077 21 8V8H15V3Z" fill="url(#paint0_linear_1565_30163)"/>
                <path d="M3 3H5C6.86923 3 7.80385 3 8.5 3.40192C8.95606 3.66523 9.33477 4.04394 9.59808 4.5C10 5.19615 10 6.13077 10 8V8H3V3Z" fill="url(#paint1_linear_1565_30163)"/>
                <path d="M10 8H15V17C15 18.8692 15 19.8038 14.5981 20.5C14.3348 20.9561 13.9561 21.3348 13.5 21.5981C12.8038 22 11.8692 22 10 22V22V8Z" fill="url(#paint2_linear_1565_30163)"/>
                <defs>
                <linearGradient id="paint0_linear_1565_30163" x1="20.5" y1="4" x2="15" y2="8" gradientUnits="userSpaceOnUse">
                <stop stop-color="#9155FE"/>
                <stop offset="1" stop-color="#1EB0FE"/>
                </linearGradient>
                <linearGradient id="paint1_linear_1565_30163" x1="3" y1="3" x2="10" y2="8" gradientUnits="userSpaceOnUse">
                <stop stop-color="#F2532E"/>
                <stop offset="0.392526" stop-color="#AD8BC2"/>
                <stop offset="0.520516" stop-color="#8594DD"/>
                <stop offset="1" stop-color="#1BB6FF"/>
                </linearGradient>
                <linearGradient id="paint2_linear_1565_30163" x1="10" y1="8" x2="14" y2="21.5" gradientUnits="userSpaceOnUse">
                <stop stop-color="#1AB5FF"/>
                <stop offset="1" stop-color="#0EC979"/>
                </linearGradient>
                </defs>
            </svg>
        </a>
    </Group>

  )
}

export { Logo, LogoShort }