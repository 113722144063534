import React from 'react'
import { Container, Section, Space, Title1, Body } from '@themeable/react-components'

function NotFoundPage() {
  return (
    <>
    <Section>
        <Container>
          <Section>
              <Title1>404</Title1>
              <Body>Page not found.</Body>
          </Section>
        </Container>
    </Section>
    </>
  )
}

export default NotFoundPage