// rfce
import React, { useEffect, useState } from 'react'
import { Logo } from '../../Patterns/Logo'
import { Input, Group, Tag, Headline, Footnote, Media, Body, HStack, Title2, Title3, Title1, Icon, Section, Space, VStack, Divider, Container, Button, Caption1, Accordion, Subhead } from '@themeable/react-components';
import { SubscribeBanner } from '../SubscribeBanner';
import MeetTheFam from './MeetTheFam';
import Hero from './Hero';
import FormSheet from '../temp-ts/FormSheet';

function Home({ globals, setGlobals }) {
	const[loading, setLoading] = useState(true);
	const [copiedIndex, setCopiedIndex] = useState(null);
	const [appointmentModal, setAppointmentModal] = useState(false);
	const [formSuccess, setFormSuccess] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');


	useEffect(() => {
		setGlobals((prev) => ({
			...prev,
			page: 'home',
			pageColor: '--color-background-elevated'
		})); 
	}, [])

	useEffect(() => {
		fetch('../api/all-blogs')
		.then((res) => res.json())
		.then((res) => { 
			setGlobals((prev) => ({
				...prev,
				blogs: res
			})); 
			setLoading(false);
		})
		.then((res) => {
			console.log(globals.blogs)
		})
		.catch((error) => {	console.error('Error fetching data: ', error); });}, 
	[]);

	function copyLink(id, index) {
        const url = `${window.location.href.split('#')[0]}#${id}`;

        navigator.clipboard.writeText(url).then(() => {
            setCopiedIndex(index);
            setTimeout(() => {
                setCopiedIndex(null);
            }, 2000);
        }).catch(err => {
            console.error('Failed to copy link:', err);
        });
    }



	const smoothScrollTo = (id) => {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    useEffect(() => {
        const hash = window.location.hash.replace('#', '');
        if (hash) {
            smoothScrollTo(hash);
        }
    }, []);

	const isAccordionActive = (id) => {
		const hash = window.location.hash.replace('#', '');
		return hash === id;
	};
	
	const toggleAppointmentSheet = () => {
		console.log('somthing')
		setAppointmentModal(!appointmentModal)
	}
    

  return (
    <>
		{/* <FormSheet variant="someVariant" active={appointmentModal} dismiss={() => {toggleAppointmentSheet()}}>
				<Section>
					<HStack gridDesktop={3} gridMobile={3} gridTablet={3}>
						<div />
						<VStack layout='center middle'>
							<Headline>
								Say Hello
							</Headline>
						</VStack>
						<VStack layout='right'>
							<Button small variant='secondary' icon1='Close' onClick={() => {toggleAppointmentSheet()}}/>
						</VStack>
					</HStack>
					
					<div id="schedule_form">
						<div 
							className="calendly-inline-widget"
							data-url="https://calendly.com/username/15min"
							style={{ minWidth: '320px', height: '580px' }} />
					</div>
					
					<div className="calendly-inline-widget" dataURL='https://calendly.com/themeable/30min' style={{width: '320px', height: '700px'}}></div>
					<script type="text/javascript" src="https://assets.calendly.com/assets/external/widget.js" async></script>


				</Section>
			</FormSheet> */}

	<VStack style={{overflowX: 'hidden'}}>



		<Hero />

		
	<Section style={{paddingBottom: '0px'}}>

				
		<VStack>
			<Title2>
				With just a few clicks, you can generate a complete and consistent design system that will improve your design workflow.
			</Title2>
			<Body color='--color-text-secondary'>
				​We’ve been designing and building beautiful digital products since 2018.
			</Body>
		</VStack>
		<Space.Content />
		<Space.Content />
		<Space.Content />
		<Space.Content />
		<HStack gridDesktop={2} gridMobile={1} gridTablet={2} layout='middle'>
			<HStack>
				<VStack>
					<HStack>
						<Divider vertical />
						<Space.Content />
						<VStack layout='left'>
							<Group>
								<Title3>Foundations</Title3>
								<Body>Create dynamic tokens for color, spacing, size, and text that sync with Figma and live websites.</Body>
							</Group>
							{/* <Button variant='tertiary' text1='Learn more [need to adddddd!!!!]' icon2='ChevronRight' url=''/> */}
						</VStack>
					</HStack>
					<HStack>
						<Divider vertical />
						<Space.Content />
						<VStack layout='left'>
							<Group>
								<Title3>Components</Title3>
								<Body>We provide accessible components with spacing and sizing built in that make it easy to create beautiful layouts.</Body>
							</Group>
							{/* <Button variant='tertiary' text1='Learn more [need to adddddd!!!!]' icon2='ChevronRight' url=''/> */}
						</VStack>
					</HStack>
					<HStack>
						<Divider vertical />
						<Space.Content />
						<VStack layout='left'>
							<Group>
								<Title3>Patterns*</Title3>
								<Body>
								Utilize Themeable's patterns and if they don't work, create your own.</Body>
							</Group>
						</VStack>
					</HStack>
					<Footnote color='--text-secondary'>*Planned in a coming update</Footnote>
				</VStack>
			</HStack>
			<Media url='../assets/themeable-systems-system-creation.png' caption='image of creating a themeable system' width='50vw'/>
		</HStack>

	</Section>

	{/* <VStack style={{position: 'relative', height: '0px'}} showOn='desktop'>
		<VStack style={{position: 'absolute', transform: 'translate(24px, -50%) rotate(0deg)'}}>
			<Icon
				name='Pattern1' 
				svgPath='../Illustrations.svg'
				size='200px'
				color='--color-accent'
				/>
		</VStack>
	</VStack> */}

	<Section style={{maxWidth: '10000px', marginTop: '0px'}}>
		<Container variant='outline'>
		<Container variant='fill'>	
		<VStack style={{overflow: 'hidden'}}>

				<Section>
					<Group>
						<Caption1 align='center'>Our Capabilities</Caption1>
						<Title2 color='--color-accent' align='center'>We’re here every step of the way</Title2>
					</Group>
						<Space.Content />
						<Space.Content />
					<HStack gridDesktop={3} gridMobile={2} gridTablet={3} layout='middle'>
						{/* `foo` should be an array to use `map`. Let's assume it's an array of blog objects. */}
						{[
							{ 
								title: 'Simplified Design System Creation', 
								description: 'Effortlessly build comprehensive design systems in minutes, not months.' 
							},
							{ 
								title: 'Enhanced Collaboration', 
								description: 'Foster seamless teamwork and real-time collaboration for your design team.' 
							},
							{ 
								title: 'Accelerated Design Workflow', 
								description: 'Streamline your design process and deliver projects faster than ever before.' 
							},
							{ 
								title: 'Customizable and Scalable', 
								description: 'Easily customize and adapt components to match your unique branding and project requirements.' 
							},
							{ 
								title: 'Hassle-Free Maintenance', 
								description: 'Efficiently manage and update your design system with robust version control.' 
							},
							{ 
								title: 'Empowering User Experiences', 
								description: 'Create exceptional user experiences with a purpose-built tool designed for designers.' 
							},
						].map((valueProp, index) => (
							<> 
							<VStack>
								<Group>
									<Headline align='center'>{valueProp.title}</Headline>
									<Space.Content />
									<Caption1 color='--color-text-secondar' modifier='regular' align='center'>{valueProp.description}</Caption1>
									<Space.Content />
								</Group>
							</VStack>
							</>
						))}
					</HStack>
				</Section>
			</VStack>

		</Container>
		</Container>
	</Section>
	<Section>
		<VStack>
			<Title2>Frequently asked questions</Title2>
			<Space.Content />
			{[
				{
					question: 'What is Themeable?',
					answer: 'Themeable is a powerful tool that hosts styles for our Figma plugin and developer components, designed to simplify the creation and management of design systems. It helps designers streamline their workflows, enhance collaboration, and deliver outstanding results in record time.'
				},
				{
					question: 'How does Themeable benefit designers?',
					answer: 'Themeable empowers designers by providing a user-friendly interface and a curated collection of templates, components, and styles. It enables them to establish consistent design systems, customize them easily, and collaborate seamlessly with their team.'
				},
				{
					question: 'Can Themeable be used by both individuals and teams?',
					answer: 'Absolutely! Themeable caters to both individual designers and collaborative design teams. It facilitates real-time collaboration, version control, and efficient management of design systems, making it ideal for both solo designers and team-based projects.'
				},
				{
					question: 'What is Themeable compatible with?',
					answer: 'Themeable is specifically built as a Figma app. It seamlessly integrates into Figma, making it a convenient and powerful tool within the Figma design environment.'
				},
				{
					question: 'Can I customize the components and styles in Themeable?',
					answer: "Absolutely! Themeable provides a range of customizable templates, components, and styles that can be easily tailored to match your project's unique branding and requirements. You have the flexibility to adapt and modify them according to your design needs."
				},
				{
					question: 'How does Themeable handle updates and maintenance of design systems?',
					answer: 'Themeable simplifies the ongoing management and maintenance of design systems. It offers robust version control, allowing you to track changes, roll back if needed, and effortlessly distribute updates to your team. This ensures your design system remains up to date and synchronized.'
				},
				{
					question: 'Can I provide feedback and suggest new features for Themeable?',
					answer: 'Absolutely! We value your input and feedback. You can reach out to us through our support channels or community forums to share your suggestions, request new features, and provide feedback on your experience with Themeable. We appreciate your contribution in shaping the future of the app.'
				},
				{
					question: 'Is there a trial or demo version available for Themeable?',
					answer: 'Currently, we are offering a beta release of Themeable where a limited number of users can participate and provide feedback. Please visit our website to check for availability and explore the beta release. We may also offer trial or demo versions in the future, so stay tuned for updates!'
				},

			].map((element, index, array) => (
				<>
    			<div key={index} id={`faq-${index}`}>
					<VStack>
						<Accordion 
							defaultActive={isAccordionActive(`faq-${index}`)}
							parentContent={<>
								<Headline>
									{element.question}
								</Headline>
							</>}
						showDivider={index !== array.length - 1}>
							<VStack layout='left top'>
								<Group>
									<Body>
										{element.answer}
									</Body>
									<Button 
										icon1='Link' 
										text1={copiedIndex === index ? 'Link copied' : ''} 
										small 
										stacked
										variant='tertiary'
										onClick={() => {copyLink(`faq-${index}`, index)}}
										/>
								</Group>
							</VStack>

						</Accordion>
					</VStack>
				</div>
				</>
			))}
		</VStack>
	</Section>

	<Section>
		<Container variant='fill'>
			<Section>
				<VStack layout='center'>
					<VStack layout='center' style={{maxWidth: '600px'}}>
						<Caption1 align='center' color='--color-accent'>Unlock the Power of Themeable for Your Enterprise</Caption1>
						<Title2 align='center' >Team over 10?</Title2>
						<Space.Content />
						<Body align='center'>
							We built Themeable for the small and big teams looking to work smarter, not harder in creating, managing and scaling design systems.
						</Body>
						<Footnote align='center'>
							Contribution, Version control, SSO, Advanced Analytics
						</Footnote>
						<Button text1="Let's Talk 'Enterprise'" variant='primary'/>
					</VStack>
				</VStack>
			</Section>
		</Container>
	</Section>

	<Section>
		<Caption1 color='--color-accent'>Contact us</Caption1>
		<Title2>We’d love to hear from you</Title2>
		<Space.Content />
		<Body>Our friendly team is always here to chat.</Body>
		<HStack gridDesktop={4}>
		{/* <Container variant='fill'>
				<VStack layout='left'>
					<Button variant='primary' small icon1='Calendar' />
					<Group>
						<Headline>
							Talk to sales
						</Headline>
						<Subhead>
						Talk to our friendly team.
						</Subhead>
					</Group>
					<Button variant='tertiary' small text1='Schedule chat' onClick={() => {toggleAppointmentSheet()}}/>
				</VStack>
			</Container> */}
			<Container variant='fill'>
				<VStack layout='center'>
					<Button variant='primary' small icon1='Mail' />
					<Group>
						<Headline>
							Talk to support
						</Headline>
						<Subhead>
							We’re here to help.
						</Subhead>
					</Group>
					<Button variant='tertiary' small text1='brad@themeable.systems' url='mailto:brad@themeable.systems"'/>
				</VStack>
			</Container>
		</HStack>
	</Section>
	<SubscribeBanner />
	{/* <MeetTheFam /> */}
	</VStack>

    </>
  )
}

export default Home;