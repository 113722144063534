import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Group, Tag, Button, HStack, Section, Container, VStack, Space, Divider, Loader, Media, Title1, Title2, Title3, Headline, Body, Subhead, Footnote, Caption1, Caption2, Icon } from '@themeable/react-components';
import GlobalFooter from '../Patterns/GlobalFooter'

function Blog({ globals, setGlobals }) {
    const[loading, setLoading] = useState(true);
	const[filteredLayout, setFilteredLayout] = useState(false);

	useEffect(() => {
		setGlobals((prev) => ({
			...prev,
			page: 'education',
			pageColor: '--color-background-elevated'
		})); 
	}, [])

	const [tagNames, setTagNames] = useState([]);
	useEffect(() => {
		if (globals.blogs != null) {
			setLoading(false);
			console.log(globals.blogs)
			const tags = globals.blogs.live.flatMap(blog =>
				blog.properties.Tags.multi_select.map(tag => tag.name)
			);
			const uniqueTags = [...new Set(tags)];
			setTagNames(uniqueTags);
		}
	}, [globals.blogs]);
	
	
    const filterBlogsBy = useCallback((criteria) => {
		fetch(`/api/filter-blogs/${criteria}`)
		.then((res) => res.json())
		.then((res) => {
			setGlobals((prev) => ({
				...prev,
				blogs: res
			})); 
			setFilteredLayout(true)
		})
	});	
    
    useEffect(() => {
		fetch('/api/all-blogs')
		.then((res) => res.json())
		.then((res) => { 
			setGlobals((prev) => ({
				...prev,
				blogs: res
			})); 
		})
		.catch((error) => {	console.error('Error fetching data: ', error); });}, 
	[]);

return (
    <>
		<VStack style={{overflow: 'hidden'}}>

			<HStack style={{position: 'relative'}} showOn='desktop tablet'>
				<div style={{position: 'absolute', 
					left: '-40px',
					transform: 'translateX(32px) rotate(0deg)', opacity: '1'}}>
					<Icon 
						name='PatternTopLeft'
						svgPath='../Illustrations.svg'
						size='300px'
						color='--color-primary'
						/>
				</div>
			</HStack>
			

			<VStack style={{background: `linear-gradient(to bottom, rgba(255, 255, 255, 0.2), rgba(0, 0, 0, 0.05)), rgb(var(--color-background-flat))`}}>
				<Space.NewSection/>
				<Section style={{maxWidth: '570px'}}>
					<VStack layout='center'>
						<Title1 tag='h1' align='center'>Design System Education</Title1>
						<Body color='--color-text-secondary' tag='h2' align='center'>
							The place to be for education on design systems. Learn advanced techniques for building the best design system for your business.
						</Body>
					</VStack>
				</Section>
				<Space.NewSection/>
			</VStack>

			<HStack style={{position: 'relative'}} showOn='desktop tablet'>
				<div style={{position: 'absolute', right: '56px', bottom: '56px', transform: 'translate(0%, 50%) rotate(-45deg)',}}>
					<Icon 
						name='PatternBottom'
						svgPath='../Illustrations.svg'
						size='600px'
						color='--color-accent'
						/>
				</div>
			</HStack>

		</VStack>

		{/* Topics tag/chip filter bar */}
		<Section>
			<HStack layout='top'>
				{loading ? (
				<>
				<Group>
					<Button variant='secondary' loading />
					<Button variant='secondary' loading />
					<Button variant='secondary' loading />
				</Group>
				</>
				) : (
				<>
					<HStack wrap layout='left middle'>
						<Subhead>
							Topics:
						</Subhead>
						<Group>
							{tagNames.map((element, index) => (
								<Button key={index} small text1={element} variant='secondary' onClick={(() => filterBlogsBy(element))} />
								))}
						</Group>
					</HStack>
				</>
				)}
			</HStack>
			<Divider />
		</Section>

		{filteredLayout ? (<>
		<Section>
			<HStack gridDesktop={6} gridTablet={4} gridMobile={2} layout='top'>
				{loading ? 
					<>
					<Loader />
					</> : <>
					{globals.blogs.live.map((blog, index) => (
					
					<Button variant='quaternary' url={`education/${blog.properties.URL.url}`}>
						<VStack layout='left'>
							<Media 
								url={blog.properties.Cover.files[0]?.file.url} 
								caption={blog.properties.Cover.files[0]?.name}
								aspectRatio='4-3'
								/>
								<Headline>
									{blog.properties.Name.title[0]?.plain_text}
								</Headline>
							<Space.Content/>
							<HStack wrap>
								<Group>
									{blog.properties.Tags.multi_select.map((tag, index) => (
										<Tag status='neutral' text1={tag.name}/>
									))}
								</Group>
							</HStack>
						</VStack>
					</Button>    
					))}
				</>}
			</HStack>
		</Section>

		</>):(<>

		{/* Featured + 3 newest blogs */}
		<Section>
			{loading ? 
			<>
			<Loader/>
			</> : <>
			<HStack 
				gridDesktop={2} 
				gridTablet={2}
				gridMobile={1} >

				{/* Featured */}
				<HStack layout='top'>
					<Media 
						url={globals.blogs.favorite?.properties.Cover.files[0]?.file.url} 
						alt={globals.blogs.favorite?.properties.Cover.files[0]?.name} 
						size='large' aspectRatio='1-1'/>
						<Space.Content/>
					<VStack layout='top left'>
						<Subhead color='--color-text-secondary'>Featured</Subhead>
						<Title3 color='--color-primary'>{globals.blogs.favorite?.properties.Name.title[0].plain_text}</Title3>
						<Subhead color='--color-text-secondary'>{globals.blogs.favorite?.properties.Description?.rich_text[0].plain_text}</Subhead>
						<Space.Content/>
						<Button small text1='Read now' variant='tertiary' icon2='ChevronRight' url={`education/${globals.blogs.favorite?.properties.URL.url}`}/>
					</VStack>
					<HStack showOn='desktop tablet'>
						<Space.NewSection/>
					</HStack>

				</HStack>
				<VStack showOn='mobile'>
					<Space.NewSection/>
					<Divider />
					<Space.NewSection/>
				</VStack>

				{/* Top 3 newest */}
				<HStack>
					<HStack showOn='desktop tablet'>
						<Divider vertical/> 
						<Space.NewSection/>
					</HStack>
					<VStack>
					{globals.blogs.live.slice(0, 3).map((blog, index) => (
						<>
						<Button variant='quaternary' url={`education/${blog.properties.URL.url}`}>
							<HStack layout='top'>
								<VStack>
									<HStack>
										<Group>
											{blog.properties.Tags.multi_select.map((tag, index) => (
												<Tag status='neutral' text1={tag.name}/>
											))}
											</Group>
										</HStack>
										<Group>
											<Headline>{blog?.properties?.Name?.title[0]?.plain_text}</Headline>
											<Footnote color='--color-text-secondary'>
												{blog.properties.Description?.rich_text[0]?.plain_text}
											</Footnote>
										</Group>
								</VStack>
								<VStack>
									<Media url={blog.properties.Cover.files[0]?.file.url}  size='medium' aspectRatio='4-3'/>
								</VStack>
							</HStack>
						</Button>
						<Divider/>
						</>
					))}
					</VStack>
				</HStack>
			</HStack>
			
			</>}
		</Section>


		<Section>
		<HStack gridDesktop={6} gridTablet={4} gridMobile={2} layout='top'>
			{loading ? 
			<>
			<Loader />
			</> : <>
			{globals.blogs.live.slice(3).map((blog, index) => (
			
			<Button variant='quaternary' url={`education/${blog.properties.URL.url}`}>
				<VStack layout='left'>
					<Media 
						url={blog.properties.Cover.files[0]?.file.url} 
						caption={blog.properties.Cover.files[0]?.name}
						aspectRatio='4-3'
						/>
						<Headline>
							{blog.properties.Name.title[0]?.plain_text}
						</Headline>
					<Space.Content/>
					<HStack wrap>
						<Group>
							{blog.properties.Tags.multi_select.map((tag, index) => (
								<Tag status='neutral' text1={tag.name}/>
							))}
						</Group>
					</HStack>
				</VStack>
			</Button>    
			))}
		</>}
		</HStack>
		</Section>

		</>
		)}

	</>
)
}

export default Blog