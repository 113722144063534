import { HStack, Space, Icon, Media, Section, Title1, Title3, VStack, Subhead, Headline, Container } from '@themeable/react-components'
import React from 'react'


function Hero() {return (<>

    <VStack style={{position: 'relative', background: `linear-gradient(to bottom, rgba(255, 255, 255, .1), rgba(0, 0, 0, 0.0)), rgb(var(--color-background-flat))`, overflow: 'hidden'}}>

        <Section>
            <HStack gridDesktop={1} gridTablet={1} gridMobile={1} style={{zIndex: '2'}}>
                <HStack>
                    <VStack>

                        <Title1>Our Figma plugin makes creating a design system a breeze
                            <span style={{position: 'relative'}}>
                            .
                            <div style={{
                                position: 'absolute', 
                                background: 'rgb(var(--color-primary))', 
                                width: '200px', 
                                height: '24px',
                                bottom: '0px',
                                zIndex: '-1',
                                opacity: '.2',
                                right: '0px'
                                }}/>
                            </span>
                        </Title1>
                        
                        <Headline color='--color-text-secondary'>
                            Figma Design systems at the click of a button
                        </Headline>
                        <Space.Content />
                    </VStack>

                </HStack>
                <Media wrapperStyle={{boxShadow: '0px 4px 10px -2px rgba(0, 0, 0, .3)'}} url='../assets/themeable-systems-hero-graphic.webm' type='video' autoPlay loop aspectRatio='2-1'/>

            </HStack>
        </Section>
    </VStack>
    <HStack style={{position: 'relative'}} showOn='desktop tablet'>
			{/* Blob illustration */}
			{/* <div style={{position: 'absolute', bottom: '0px', left: '64px', width: '700px', height: '700px'}}>
				<Space.Grow />
				<Icon
					name='PatternBottom' 
					svgPath='../Illustrations.svg'
					size='700px'
					/>
			</div> */}
			
		</HStack>

</>)}

export default Hero