import { Input, Section, Icon, VStack, Title2, Body, Image, Space, HStack, Button, Footnote, Subhead, Container, Label } from '@themeable/react-components'
import React, { useState, useEffect } from 'react'
// import Input from './temp-ts/Input'
import { collection, addDoc, Timestamp, arrayUnion, updateDoc, doc, addField } from "firebase/firestore";
import { db } from '../services/firebase';

function SubscribeBanner() {

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [errorMessage, setErrorMessage] = useState(null);
    const [formSuccess, setFormSuccess] = useState(false)
    
    const sendSubscribeInfo = () => {

        const isValidName = (name) => name && /^[a-zA-Z-' ]+$/.test(name);

        // Email validation using a regular expression
        const isValidEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

        if (!isValidName(firstName)) {
            console.error('Invalid first name');
            setErrorMessage('Invalid first name');
            return;
        }

        if (!isValidName(lastName)) {
            console.error('Invalid last name');
            setErrorMessage('Invalid last name');
            return;
        }

        if (!isValidEmail(email)) {
            console.error('Invalid email');
            setErrorMessage('Invalid email');
            return;
        }

        const encodedEmail = email.replace(/\./g, ',');

        updateDoc(doc(db, 'subscriptions', 'generic'), {
            [encodedEmail]: {
                firstName: firstName,
                lastName: lastName,
                status: 'active',
                lastUpdated: Timestamp.now(),
                source: window.location.href
            }
        })
        .then(() => {
            setFormSuccess(true);
        })
        .catch((error) => {
            setErrorMessage(error.message);
        });
    };



  return (
    <>
        <div style={{background: 'rgb(var(--color-background-flat))', zIndex: '3', position: 'relative', 
        }}>
            <Section>
                <HStack gridDesktop={2} gridMobile={1} gridTablet={2} layout='top'>

                    {/* Left column */}
                    <HStack>

                        <VStack>

                            <Title2>
                                We’ll send you a nice letter whenever we have an update
                            </Title2>
                            <Body color='--color-text-secondary'>No spam. Just the latest releases and tips, interesting articles, and exclusive interviews with great people.</Body>

                        </VStack>
                        <Space.Content />
                        <Space.Content />
                        <Space.Content />
                    </HStack>
                    
                    {/* Right column (image) */}
                    <VStack>
                        {formSuccess ? (<>
                                    <Subhead>
                                        Thanks for subscribing!
                                    </Subhead>
                                </>):(<>
                                    <HStack>
                                        <Label text1='First name'>
                                            <Container>
                                                <Input placeholder='John' variant='default' label='First name' onChange={((e) => {setFirstName(e.target.value)})}/>
                                            </Container>
                                        </Label>
                                        <Label text1='Last name'>
                                            <Container>
                                                <Input placeholder='Smith' variant='default' label='Last name' onChange={((e) => {setLastName(e.target.value)})}/>
                                            </Container>
                                        </Label>
                                    </HStack>
                                    <Label text1='Email'>
                                    <Container>
                                        <Input placeholder='johnsmith@themeable.systems' variant='default' label='Email' onChange={((e) => {setEmail(e.target.value)})}/>
                                    </Container>
                                    </Label>
                                    {errorMessage && (
                                        <>
                                            <Footnote color='--color-negative'>
                                                {errorMessage}
                                            </Footnote>
                                        </>
                                    )}
                                    <HStack>
                                        <Button text1='Subscribe' variant='primary' onClick={sendSubscribeInfo}/>
                                    </HStack>
                                </>)}
                    </VStack>
                </HStack>
            </Section>
        </div>
    </>
  )
}

export { SubscribeBanner }