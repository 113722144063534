import React, { useState, useEffect } from 'react'
import { Section, HStack, VStack, Body, Title3, Headline, Button, Group, Divider, Space, Footnote } from '@themeable/react-components'
import { Logo } from './Logo'

function GlobalFooter() {

    const [year, setYear] = useState('');

    useEffect(() => {
       setYear(new Date().getFullYear())
    }, [])
    


    return (<>
        <div style={{background: 'rgb(var(--color-background-elevated))'}}>
            <Section>
                <HStack gridDesktop={2} gridTablet={2} gridMobile={2}>
                    <Logo width={'200px'} />
                    {/* <VStack layout='left'>
                        <Headline>
                            Navigate to:
                        </Headline>
                        <Group>
                            <Button 
                                small
                                variant='quaternary'
                                text1='Home'
                                url='/'
                                />
                            <Button 
                                small
                                variant='quaternary'
                                text1='Blog'
                                url='../blog'
                                />
                            <Button 
                                small
                                variant='quaternary'
                                text1='About'
                                url='../about'
                                />
                        </Group>
                    </VStack> */}
                </HStack>
                <Space.Content />
                <Divider />
                <HStack>
                    <Footnote>
                        Copyright © {year} Themeable Systems LLC. All rights reserved.
                    </Footnote>
                </HStack>
            </Section>
        </div>
    </>)
}

export default GlobalFooter