import { Section, VStack, HStack, Title3, Body, Space, Tab, Button, Subhead, Popover, Headline, Divider, Group, Cell, Container, Icon } from '@themeable/react-components'
import React, { useEffect, useState, useRef } from 'react'
import './Navbar.css'
import { Logo, LogoShort } from './Logo';
import FormSheet from '../Pages/temp-ts/FormSheet'
import Input from '../Pages/temp-ts/Input'
import { collection, addDoc, Timestamp, arrayUnion, updateDoc, doc, addField } from "firebase/firestore";
import { db } from '../services/firebase';


function Navbar({ setGlobals, globals }) {
	const [scrollPosition, setScrollPosition] = useState(0);
	const navbarRef = useRef('--color-positive');
	// const [isFormSheetActive, setIsFormSheetActive] = useState(false);
	const toggleFormSheet = () => {
	  setGlobals((prev) => ({
		...prev,
		sayHelloSheet: !globals.sayHelloSheet
	})); 
	};

	useEffect(() => {

		// window.addEventListener('scroll', () => setScrollPosition(window.scrollY));

		if (navbarRef.current) {
		  	const height = navbarRef.current.clientHeight;
			setGlobals((prev) => ({
				...prev,
				// navHeight: height
				navHeight: '56px'
			})); 
		}

	  }, []);

	  const [formSuccess, setFormSuccess] = useState(false)
	  const [name, setName] = useState('')
	  const [email, setEmail] = useState('')
	  const [message, setMessage] = useState('')
	  const [errorMessage, setErrorMessage] = useState('')

	  const sendHello = () => {

        const isValidName = (name) => name && /^[a-zA-Z-' ]+$/.test(name);

        // Email validation using a regular expression
        const isValidEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

        if (!isValidName(name)) {
            console.error('Invalid name');
            setErrorMessage('Invalid name');
            return;
        }

        if (!isValidEmail(email)) {
            console.error('Invalid email');
            setErrorMessage('Invalid email');
            return;
        }

        const encodedEmail = email.replace(/\./g, ',');

        updateDoc(doc(db, 'subscriptions', 'say-hello'), {
            [encodedEmail]: {
                name: name,
                status: 'active',
                lastUpdated: Timestamp.now(),
                source: window.location.href,
				message: message
            }
        })
        .then(() => {
            setFormSuccess(true);
        })
        .catch((error) => {
            setErrorMessage(error.message);
        });
    };

  return (
    <>
		<nav 
		className={`navbar ${scrollPosition > 0 ? 'sticky' : ''}`} ref={navbarRef}
		style={{background: `rgba(var(${globals.pageColor}), .8)`}}>
			<VStack>
				<Section style={{paddingTop: '0px', paddingBottom: '0px'}}>
					<HStack layout='left middle'>
						<Logo width='200px' />
						<Space.Content />
						<HStack layout='left' showOn='desktop tablet'>

							<Tab 
								text1='Home' url='../' type='link' indicator='none'
								active={ globals.page === 'home' ? true : false }/>
							<Tab 
								text1='Education' url='../education' type='link' indicator='none'
								active={ globals.page === 'education' ? true : false }/>
						</HStack>
						<Space.Grow/>

						<HStack className='navTabs' showOn='desktop tablet' layout='right'>
							
							<Group>
								<Button variant='primary' small text1='Create System' icon1='Plus' url='https://themeable.systems' />
								<Button variant='secondary' small text1='Demo Figma' url='https://www.figma.com/community/plugin/1237797609661137426/themeable-systems' />
							</Group>

						</HStack>
						<HStack showOn='mobile' layout='right'>
						<Popover trigger={
							<Button className='navHamburger' icon1='Hamburger' variant='quaternary'/>
						}>
							<Section>
								<VStack layout='top'>

							<Group>
								<Cell variant='link' url='../'>
									<HStack layout='middle'>
										<Cell.Text>
											<Body>
												Home
											</Body>
										</Cell.Text>
										{globals.page === 'home'?(
											<Icon name='Check' />
											):''}
									</HStack>
								</Cell>
								<Cell variant='link' url='../education'>
									<HStack layout='middle'>
										<Cell.Text>
											<Body>
												Education
											</Body>
										</Cell.Text>
										{globals.page === 'education'?(
											<Icon name='Check' />
											):''}
									</HStack>
								</Cell>
							</Group>
							<Divider extendLeft extendRight />
							<HStack>
								<Group>
									<Button variant='primary' small text1='Create System' icon1='Plus' url='https://themeable.systems' />
									<Button variant='secondary' small text1='Demo Figma' url='https://www.figma.com/community/plugin/1237797609661137426/themeable-systems' />
								</Group>
							</HStack>
							</VStack>
							</Section>
						</Popover>
						</HStack>
						
					</HStack>
					<Divider nested/>
				</Section>
			</VStack>
		</nav>

		<FormSheet variant="someVariant" active={globals.sayHelloSheet} dismiss={() => {toggleFormSheet()}}>
		<Section>
			<HStack gridDesktop={3} gridMobile={3} gridTablet={3}>
				<div />
				<VStack layout='center middle'>
					<Headline>
						Say Hello
					</Headline>
				</VStack>
				<VStack layout='right'>
					<Button small variant='secondary' icon1='Close' onClick={() => {toggleFormSheet()}}/>
				</VStack>
			</HStack>
			{formSuccess ? (<>
			<Title3>
				Thanks!
			</Title3>
			<Body>
				I'm looking forward to reading your message.
			</Body>
			</>):(<>
			<Title3>
				Hi!
			</Title3>
			<Body>
			I’m excited to connect with you. You can fill out the form below or contact me directly via email: hello@redeemingspecialneeds.com.
			</Body>
			<Space.Content />
			<Space.Content />
			<form>
				<HStack>

						<VStack>
							<Subhead style={{minWidth: '120px', marginBottom: '0px'}}>
								Name
							</Subhead>
							<Input variant='fill' onChange={((e) => {setName(e)})}/>
						</VStack>
						<VStack>
							<Subhead style={{minWidth: '120px', marginBottom: '0px'}}>
								Email
							</Subhead>
							<Input variant='fill' onChange={((e) => {setEmail(e)})}/>
						</VStack>
						
				</HStack>

				<VStack>
					<Subhead style={{minWidth: '120px', marginBottom: '0px'}}>
						Message
					</Subhead>
					<Input variant='fill' onChange={((e) => {setMessage(e)})}/>
				</VStack>

				<HStack layout='right middle'>
					<Subhead color='--color-negative'>{errorMessage}</Subhead>
					<Space.Content />

					<Button 
						onClick={() => {toggleFormSheet()}} 
						text1='Cancel'
						/>
					<Button 
						 
						type='submit' 
						onClick={((e) => {sendHello()})} 
						variant='primary'
						text1='Send'
						/>
				</HStack>
			</form>
			</>)}
		</Section>
	</FormSheet>
    </>
  )
}

export default Navbar